<template>
  <v-container class="news">
    <!-- <section class="zero">
      <p class="content animated fadeInUp">
        {{ $t("news.title") }}
      </p>
    </section>
    <div class="nb" style="width: 55%"></div> -->
    <!-- <p>{{ $t("news.p") }}</p> -->
    <!-- <img src="@/assets/img/rectangle.png" alt="" /> -->
    <section class="one row-b_">
      <div class="title-1 animated fadeInUp">{{ $t("news.newsTitle") }}</div>

      <v-row class="left content-b mt-14 animated fadeInUp">
        <v-col cols="12" md="6" v-for="(item, index) in news" :key="index">
          <div class="item">
            <div class="index pr-5">
              {{ index + 1 > 10 ? "" : "0" + (index + 1) }}
            </div>
            <div class="info1">
              <div class="title">{{ $t(item.title) }}</div>
              <div class="mt-4 d-sm-flex justify-space-between">
                <div class="date">
                  {{$t( item.date) }}
                </div>
                <div><img :src="item.ims" alt="" /></div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </section>
    <ButtomNav></ButtomNav>
  </v-container>
</template>
<script>
import ButtomNav from "@/components/buttomNav.vue";
export default {
  components: {
    ButtomNav,
  },
  data() {
    return {
      news: [
        {
          date: "news.forDate.ins3",
          title: "news.forTitle.ins3",
          ims: require("../assets/img/ins3_bg.png"),
        },
        {
          date: "news.forDate.one",
          title: "news.forTitle.one",
          ims: require("../assets/img/bitmap-tow.png"),
        },
        {
          date: "news.forDate.tow",
          title: "news.forTitle.tow",
          ims: require("../assets/img/Phala_bg.png"),
        },
        {
          date: "news.forDate.three",
          title: "news.forTitle.three",
          ims: require("../assets/img/bitmap.png"),
        },
        {
          date: "news.forDate.four",
          title: "news.forTitle.four",
          ims: require("../assets/img/bitmap-four.png"),
        },
        {
          date: "news.forDate.five",
          title: "news.forTitle.five",
          ims: require("../assets/img/bitmap-one.png"),
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
.news {
  color: #fff;
  // padding-top: 20vh;
  > section {
    box-sizing: border-box;
  }
  .zero {
    padding-right: 35vw;
    padding-bottom: 2vh;
    .content {
      font-size: 2rem;
      line-height: 1.4;
      font-weight: 600;
    }
  }
  > .nb + p {
    font-style: 0.6vw;
    line-height: 3.5vh;
    font-weight: 600;
    margin-top: 1.5vh;
  }
  > .title {
    font-size: 3vw;
    font-weight: 900;
    margin-bottom: 4vh;
    padding: 20vh 0;
  }
  > .one {
    margin: 2vh 0 30vh;
    .left {
      width: 100%;
      .item {
        padding: 2vh 0;
        margin: 2vh 0 3vh;
        box-sizing: border-box;
        border-top: 1px #ddd dashed;

        .info1 {
          > .title {
            font-weight: bold;
            font-size: 0.9vw;
          }
          > .margin {
            margin: 20px 0;
          }
          > .date {
            font-size: 0.8vw;
            margin: 3vh 0 6vh;
            height: 18vh;
          }
          img {
            max-height: 140px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 940px) {
  // 移动设备
  .news {
    .zero {
      padding-right: 0;
      padding-bottom: 2vh;
    }
    > .one {
      > div {
        width: 100%;
      }
      .left {
        > .title {
          font-size: 7.5vw;
          margin-bottom: 0.5vh;
        }

        .item {
          .info1 img {
            margin-top: 2vh;
            width: 100%;
          }
        }
      }
      > .right {
        padding: 2vh 4vw;
        margin-top: 3.5vh;
        max-height: none;
        .sub {
          margin-bottom: 3vh;
          > input {
            padding-left: 4vw;
          }
          > a {
            font-size: 5vw;
          }
        }
      }
    }
  }
}
</style>